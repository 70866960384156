import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { AuthorizationRoles, SelectDto } from '#edsn/api/pie-bff';
import { getContactDetails } from '#edsn/api/pie-bff';
import { AuthorizationTag } from '#pie/components/authorization-tag/AuthorizationTag';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { OverflowText } from '#pie/components/overflow-text/OverflowText';
import { Stack } from '#pie/components/stack/Stack';
import { StatusTag } from '#pie/components/status-tag/StatusTag';
import { useDataTable } from '#pie/hooks/useDataTable';
import { contactDetailsSortingAtom } from '#pie/stores/contactDetails';
import { searchAtom } from '#pie/stores/search';
import { emptyFilterAtom } from '#pie/stores/searchOnlyFilter';

export const UserManagement = () => {
  const { t } = useTranslation();

  const tableProps = useDataTable({
    columns: helper => [
      helper.accessor('fullName', {
        cell: info => (
          <>
            <Link className="text-primary link" to={info.row.original.id} aria-label={info.getValue()}>
              {info.getValue()}
            </Link>
            <Link className="absolute inset-0" to={info.row.original.id} />
          </>
        ),
        header: t('common.name'),
      }),
      helper.accessor('email', {
        header: t('common.email'),
      }),
      helper.accessor('teams', {
        cell: info => {
          const teams = info.getValue() as SelectDto[];
          return <OverflowText maxWidth={250}>{teams.map(m => m.label).join(', ')}</OverflowText>;
        },
        header: t('common.teams'),
      }),
      helper.accessor('contactGroups', {
        cell: info => {
          const contactGroups = info.getValue() as SelectDto[];
          return <OverflowText maxWidth={250}>{contactGroups.map(m => m.label).join(', ')}</OverflowText>;
        },
        header: t('common.cpr_contact_groups'),
      }),
      helper.accessor('roles', {
        cell: info => {
          const roles = info.getValue() as AuthorizationRoles[];
          return (
            <div className="flex gap-1">
              {roles.slice(0, 3).map(m => (
                <AuthorizationTag role={m} />
              ))}
              {roles.length > 3 && <StatusTag status={'closed'}>+{roles.length - 3}</StatusTag>}
            </div>
          );
        },
        header: t('common.roles'),
      }),
    ],
    dateKeys: [],
    filtersAtom: emptyFilterAtom,
    query: getContactDetails,
    sortingAtom: contactDetailsSortingAtom,
  });

  const [search, setSearch] = useAtom(searchAtom);

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <header className="px-3" role="toolbar">
            <InputSearch
              value={search || ''}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('common.search.placeholder')}
              onClear={() => setSearch('')}
            />
          </header>
          <DataTable {...tableProps} idKey="id" />
        </Stack>
      </Card>
    </section>
  );
};
