import { tv } from 'tailwind-variants';
import type { PropsWithChildren } from 'react';
import type { VariantProps } from 'tailwind-variants';

type StatusTagVariant = VariantProps<typeof statusTag>['status'];

export interface StatusTagProps extends PropsWithChildren {
  className?: string;
  status: StatusTagVariant;
}

export function StatusTag({ className, children, status }: StatusTagProps) {
  return <div className={statusTag({ className, status })}>{children}</div>;
}

const statusTag = tv({
  base: 'px-3 rounded-2xl text-sm text-center max-h-[25px] whitespace-nowrap',
  variants: {
    status: {
      attention: 'bg-[#FFEEEE] text-[#D71D1D]',
      closed: 'bg-neutral-100 text-[#334155]',
      completed: 'bg-[#F0FDF4] text-[#13843D]',
      new: 'bg-[#FDF4FF] text-[#8C1D9A]',
      progress: 'bg-[#F0F8FF] text-[#064F86]',
      update: 'bg-[#FFFBEB] text-[#A36419]',
    },
  },
});
