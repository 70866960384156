import AddIcon from '@iconify/icons-material-symbols/add';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserManagement } from './users/UserManagement';
import { Button } from '#pie/components/button/Button';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#pie/components/tabs';
import { UserManagementExport } from '#pie/components/user-management-export/UserManagementExport';

enum OrganisationTabs {
  UserManagement = 'gebruikersbeheer',
}

export const OrganisationManagement = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tab = location.pathname.split('/').at(2) as OrganisationTabs;

  return (
    <>
      <PageHead title={t('organisation_management_title')} />
      <Page>
        <PageHeader title={t('organisation_management_title')} className="mb-8">
          {tab === OrganisationTabs.UserManagement && <UserManagementExport />}
          {tab === OrganisationTabs.UserManagement && (
            <Button as={Link} iconStart={AddIcon} to="/organisatiebeheer/gebruikersbeheer/nieuw" variant="secondary">
              Nieuwe gebruiker
            </Button>
          )}
        </PageHeader>
        <section className="flex items-start gap-6">
          <Tabs asChild value={tab} onValueChange={val => navigate(`../${val}`)}>
            <Stack gap="lg" className="w-full">
              <TabsList>
                <TabsTrigger value={OrganisationTabs.UserManagement}>
                  {t('organisation_management.tab.user_management')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value={OrganisationTabs.UserManagement}>
                <UserManagement />
              </TabsContent>
            </Stack>
          </Tabs>
        </section>
      </Page>
    </>
  );
};
