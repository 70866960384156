import AddIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AuthorizationRoles,
  type CreateCprContactDto,
  getCprManagementContact,
  useGetContact,
  usePostCprContact,
} from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { FormContactCombobox } from '../contact-combobox/FormContactCombobox';
import { validation } from '../form/formHelpers';
import { Stack } from '../stack/Stack';
import { useToast } from '../toast/ToastContext';
import type { Option } from '../input-dropdown/InputDropdown';
import type { SubmitHandler } from 'react-hook-form';
import { useCurrentOrganisation } from '#pie/auth';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { typedFormFields } from '#pie/utils/typedFormFields';

interface Props {
  contactGroupId: string;
}

const { FormDropdown } = typedFormFields<CreateCprContactDto>();

export const AddCprContactModal = ({ contactGroupId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const { currentAccount } = useCurrentOrganisation();
  const canAddContact = useUserHasRole({ role: AuthorizationRoles.CprAdmin });
  const subOrganisations = currentAccount?.subOrganisations;
  const { data: contacts, isLoading: isContactsLoading } = useGetContact();
  const { addToast } = useToast();
  const showAdministrativeDropdown = subOrganisations && subOrganisations.length > 1;

  const subOrganisationsOptions: Option[] =
    subOrganisations?.map(item => ({
      label: item.label,
      value: item.id,
    })) ?? [];

  const formMethods = useForm<CreateCprContactDto>({
    defaultValues: { contactGroupId },
  });

  const { handleSubmit } = formMethods;

  const { mutate, isLoading: isPosting } = usePostCprContact({
    mutation: {
      onError: () => {
        addToast({
          message: t('cpr_contact_group_detail.add_user.toast.error.message'),
          title: t('cpr_contact_group_detail.add_user.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: (data, variables) => {
        if (data.id) {
          gtag('event', 'link_cpr_contact', {});
          queryClient.invalidateQueries([getCprManagementContact.name]);
          queryClient.invalidateQueries(['/cpr-management-contact']);
          formMethods.reset();
          addToast({
            message: t('cpr_contact_group_detail.add_user.toast.success.message', {
              name: contacts?.items.find(item => item.id === variables.data.contactId)?.fullName,
            }),
            title: t('cpr_contact_group_detail.add_user.toast.success.title'),
            type: 'success',
          });
          setIsOpen(false);
        }
      },
    },
  });

  const onSubmit: SubmitHandler<CreateCprContactDto> = ({ organisationId, ...data }) => {
    const firstOrganisationId = subOrganisations?.[0]?.id;

    // TODO: Find a way to test this
    /* c8 ignore start */
    if (!organisationId && !firstOrganisationId) {
      throw new Error(`organisationId can't be empty`);
    }
    /* c8 ignore end */

    mutate({ data: { ...data, contactGroupId, organisationId: organisationId || firstOrganisationId } });
  };
  const isLoading = isPosting || isContactsLoading;

  return (
    <>
      {canAddContact && (
        <Button iconStart={AddIcon} variant="secondary" onClick={() => setIsOpen(true)}>
          {t('cpr_management.contact_modal.button.add')}
        </Button>
      )}

      {isOpen && (
        <Dialog title={t('cpr_management.contact_modal.button.add')} size="md" open={isOpen} onOpenChange={setIsOpen}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="lg">
                {showAdministrativeDropdown && (
                  <FormDropdown
                    options={subOrganisationsOptions}
                    label={t('cpr_management.contact_modal.label_market_party')}
                    name="organisationId"
                    fullWidth
                    isLoading={isLoading}
                  />
                )}
                <FormContactCombobox
                  label={t('cpr_management.contact_modal.label_contact')}
                  name="contactId"
                  rules={{ required: validation.required }}
                  fullWidth
                  filter={{ excludeContactGroupIds: [contactGroupId] }}
                />
                <Button isLoading={isLoading} type="submit" variant="secondary">
                  {t('cpr_management.contact_modal.button.add')}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};
