import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import type { CreateUpdateContactDetailsDto } from '#edsn/api/pie-bff';
import { getContactDetails, usePostContactDetailsCreate } from '#edsn/api/pie-bff';
import { FormOrganisationUser } from './FormOrganisationUser';
import type { SubmitHandler } from 'react-hook-form';
import { ToastButton } from '#pie/components/toast/Toast';
import { useToast } from '#pie/components/toast/ToastContext';

export const CreateOrganisationUser = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isLoading } = usePostContactDetailsCreate({
    mutation: {
      onError: () => {
        addToast({
          message: t('organisation_management.user_management.create.toast.error.message'),
          title: t('organisation_management.user_management.create.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: ({ id }) => {
        addToast({
          action: (
            <ToastButton as={Link} to={`/organisatiebeheer/gebruikersbeheer/${id}`}>
              {t('organisation_management.user_management.create.toast.success.button')}
            </ToastButton>
          ),
          message: t('organisation_management.user_management.create.toast.success.message'),
          title: t('organisation_management.user_management.create.toast.success.title'),
          type: 'success',
        });
        navigate('/organisatiebeheer/gebruikersbeheer/');
        queryClient.invalidateQueries([getContactDetails.name]);
      },
    },
  });

  const onSubmit: SubmitHandler<CreateUpdateContactDetailsDto> = data => {
    mutate({ data });
  };

  return <FormOrganisationUser onSubmit={onSubmit} isLoading={isLoading} />;
};
