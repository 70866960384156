import IconClose from '@iconify/icons-material-symbols/close';
import * as Popup from '@radix-ui/react-dialog';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ReactNode } from 'react';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';
import { tv } from '#pie/utils/TailwindUtils';

export interface DialogProps extends VariantProps<typeof content> {
  title?: string;
  description?: string;
  children: ReactNode;
  className?: string;

  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const Dialog = ({ title, description, children, open, onOpenChange, size, className }: DialogProps) => {
  return (
    <Popup.Root open={open} onOpenChange={onOpenChange}>
      <Popup.Portal>
        <Popup.Overlay className="fixed inset-0 z-20 bg-neutral-900/25" />

        <Popup.Content className={content({ className, size })} aria-label={title}>
          {(title || description) && (
            <div className="border-b border-b-neutral-200 px-6 py-4">
              <Popup.Title asChild>
                <Text as="h1" variant="h5" className="text-primary-dark">
                  {title}
                </Text>
              </Popup.Title>

              <Popup.Description asChild>
                <Text variant="sm">{description}</Text>
              </Popup.Description>

              {onOpenChange && (
                <button
                  className="absolute right-6 top-4 focus:outline-none"
                  onClick={() => onOpenChange(!open)}
                  aria-label="Close"
                >
                  <Icon icon={IconClose} />
                </button>
              )}
            </div>
          )}

          <div className="whitespace-pre-line p-6">{children}</div>
        </Popup.Content>
      </Popup.Portal>
    </Popup.Root>
  );
};

const content = tv({
  base: 'fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-30 max-h-[85vh] w-[90vw] rounded-lg bg-white shadow focus:outline-none',

  defaultVariants: {
    size: 'lg',
  },

  variants: {
    size: {
      '2xl': 'max-w-5xl',
      lg: 'max-w-lg',
      md: 'max-w-md',
      xl: 'max-w-2xl',
    },
  },
});
