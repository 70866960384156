import * as Sentry from '@sentry/react';
import { type ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '#pie/components/button/Button';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { MODE } from '#pie/env';

interface ErrorPageProps {
  heading: string;
  message: ReactNode;
  error?: unknown;
}

export function ErrorPage({ heading, message, error }: ErrorPageProps) {
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureException(
      new Error(`${heading}: ${message}`, {
        cause: error,
      })
    );
  }, []);

  return (
    <>
      <PageHead title={heading} noIndex />
      <Stack className="items-start px-8 py-4" gap="md">
        <Text as="h1" variant="h3">
          {heading}
        </Text>
        {typeof message === 'string' ? <Text>{message}</Text> : message}
        <Button as={Link} to="/">
          {t('error_page.back_to_home')}
        </Button>
        {MODE !== 'prd' && MODE !== 'production' && typeof error !== 'undefined' && (
          <code>
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </code>
        )}
      </Stack>
    </>
  );
}
