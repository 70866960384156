import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateUpdateContactDetailsDto } from '#edsn/api/pie-bff';
import { AuthorizationRoles, useGetTeam } from '#edsn/api/pie-bff';
import type { DefaultValues, SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { InfoDialog } from '#pie/components/info-dialog/InfoDialog';
import { Page, PageHeader } from '#pie/components/page/Page';
import { SkeletonLines } from '#pie/components/skeleton-lines/SkeletonLines';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { isDefined } from '#pie/utils/isDefined';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormText, FormCheckbox } = typedFormFields<CreateUpdateContactDetailsDto>();

interface Props {
  onSubmit: SubmitHandler<CreateUpdateContactDetailsDto>;
  isLoading: boolean;
  defaultValues?: DefaultValues<CreateUpdateContactDetailsDto>;
}

export const FormOrganisationUser = ({ onSubmit, isLoading, defaultValues }: Props) => {
  const formMethods = useForm<CreateUpdateContactDetailsDto>({ defaultValues });
  const { t } = useTranslation();
  const { data, isLoading: isLoadingTeams } = useGetTeam();

  return (
    <Page>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageHeader
            title={t('organisation_management.user_management.create.header.title')}
            previousText={t('organisation_management.user_management.create.header.back')}
            backHref="/organisatiebeheer/gebruikersbeheer"
          >
            <Button type="submit" isLoading={isLoading} variant="secondary">
              {t('organisation_management.user_management.create.header.button.submit')}
            </Button>
          </PageHeader>
          <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {t('organisation_management.user_manegement.create.personal_info.title')}
                </Text>
              }
              shadow={false}
            >
              <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                <FormText
                  name="firstName"
                  label={t('common.first_name')}
                  fullWidth
                  rules={{ required: t('zod.errors.required') }}
                />
                <FormText
                  name="lastName"
                  label={t('common.last_name_prefix')}
                  fullWidth
                  rules={{ required: t('zod.errors.required') }}
                />
                <FormText
                  name="email"
                  type="email"
                  label={t('common.email')}
                  fullWidth
                  rules={{ required: t('zod.errors.required') }}
                />
                <FormText name="phone" label={t('common.phone')} fullWidth />
              </div>
            </Card>
            <Card
              size="md"
              heading={
                <Text as="h6" variant="h6">
                  {t('organisation_management.user_management.create.roles.title')}
                </Text>
              }
              shadow={false}
            >
              <Stack gap="md">
                <FormCheckbox
                  label={t('organisation_management.user_management.create.roles.label')}
                  name="authorizationRoles"
                  options={Object.values(AuthorizationRoles)
                    .map(r =>
                      r !== AuthorizationRoles.Admin && r !== AuthorizationRoles.SuperUser
                        ? {
                            card: true,
                            id: r,
                            label: t(`common.roles.${r}`),
                            value: r,
                          }
                        : undefined
                    )
                    .filter(isDefined)}
                />
              </Stack>
            </Card>
            <Card
              size="md"
              heading={
                <Stack direction="row" gap="xs" className="text-primary-dark">
                  <Text as="h6" variant="h6">
                    {t('organisation_management.user_management.create.teams.title')}{' '}
                  </Text>
                  <InfoDialog title={t('organisation_management.user_management.create.teams.info.title')}>
                    {t('organisation_management.user_management.create.teams.info.message')}
                  </InfoDialog>
                </Stack>
              }
              shadow={false}
            >
              <Stack gap="md">
                {isLoadingTeams ? (
                  <SkeletonLines lines={3} />
                ) : (
                  <FormCheckbox
                    label={t('organisation_management.user_management.create.teams.label')}
                    name="teamIds"
                    options={data!.items.map(item => ({ card: true, id: item.id, label: item.name, value: item.id }))}
                  />
                )}
              </Stack>
            </Card>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  );
};
