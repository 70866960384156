import { Icon, type IconProps } from '../icon/Icon';
import { Text } from '../text/Text';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ReactNode } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface MessageProps extends VariantProps<typeof message> {
  icon?: IconProps['icon'];
  children?: ReactNode;
  className?: string;
}

export function Message({ icon, children, variant, className }: MessageProps) {
  const s = message({ variant });

  return (
    <div className={s.base({ className })}>
      {!!icon && <Icon icon={icon} size={16} className={s.icon()} />}
      <Text>{children}</Text>
    </div>
  );
}

const message = tv({
  base: 'flex bg-transparent border border-transparent p-3 rounded-lg gap-3 items-start',
  slots: {
    icon: 'flex-shrink-0 mt-[0.2em]',
  },
  variants: {
    variant: {
      error: {
        base: 'bg-error-light border-error',
        icon: 'text-error',
      },
      info: {
        base: 'bg-info-light border-info',
        icon: 'text-info',
      },
      success: {
        base: 'bg-success-light border-success',
        icon: 'text-success',
      },
      warning: {
        base: 'bg-warning-light border-warning',
        icon: 'text-warning',
      },
    },
  },
});
